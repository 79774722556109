<template>
  <svg
    viewBox="0 0 163 142"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  ><rect
    id="Home-with-coloured-borders-condensed"
    serif:id="Home with coloured borders condensed"
    x="-268.817"
    y="-43.335"
    width="703.189"
    height="1433.73"
    style="fill:none;"
  /><g><g id="Colors"><g><path
    d="M154.233,46.825c-0,0 -31.653,-6.252 -56.449,23.751c-22.45,27.165 -4.681,46.221 3.114,54.016c7.794,7.795 35.238,15.874 50.806,-2.133c9.852,-11.395 11.785,-31.754 0.931,-48.322c-9.694,-14.798 -32.22,-8.18 -32.22,-8.18c14.297,-18.328 23.785,-17.271 33.818,-19.132Z"
    :style="`fill:${accentLittle}`"
  /><path
    d="M87.931,120.542c-13.537,6.202 -31.504,11.173 -51.041,-4.375c-19.537,-15.548 -27.875,-37.925 -10.693,-65.938c14.463,-23.581 29.454,-5.096 29.454,-5.096c9.933,-32.317 -26.558,-34.776 -46.995,-35.618c0,-0 45.132,-18.203 80.024,7.842c13.184,9.841 20.067,20.603 23.11,31.056c-7.989,4.076 -26.874,18.854 -34.385,39.293c-3.701,10.073 6.128,23.173 10.526,32.836Z"
    :style="`fill:${accentBig}`"
  /></g></g><g><path
    d="M40.616,51.483c0,-0 10.116,-12.406 -1.639,-22.13c-11.756,-9.725 -32.723,-12.463 -32.723,-12.463c-0,-0 34.126,-13.824 65.696,0.095c27.83,12.269 30.857,32.278 30.857,32.278c0,0 -12.045,-23.007 -36.87,-28.698c-22.043,-5.054 -29.573,-1.683 -29.573,-1.683c0,0 9.45,3.72 13.759,13.987c4.211,10.035 -9.507,18.614 -9.507,18.614Z"
    :style="`fill:${color}`"
  /><path
    d="M22.273,79.74c-0,0 2.726,18.467 16.221,27.937c11.174,7.841 20.945,7.481 20.945,7.481l4.92,-6.582c0,0 -14.989,0.44 -24.749,-6.404c-14.53,-10.188 -17.337,-22.432 -17.337,-22.432Z"
    :style="`fill:${color}`"
  /><path
    d="M138.739,97.451c0,-0 -0.526,11.987 -10.789,18.529c-6.469,4.124 -12.744,3.792 -12.744,3.792l-4.401,-5.955c0,0 9.101,1.773 16.125,-2.414c9.828,-5.86 11.809,-13.952 11.809,-13.952Z"
    :style="`fill:${color}`"
  /><path
    d="M96.233,124.814c0,-0 -29.813,24.648 -67.072,2.537c-38.511,-22.855 -30.69,-78.671 7.992,-96.65c0,0 -14.666,8.751 -23.107,30.202c-9.008,22.895 1.623,44.722 15.227,53.691c31.867,21.012 54.046,3.745 54.046,3.745l12.914,6.475Z"
    :style="`fill:${color}`"
  /><path
    d="M115.335,73.607c-0,0 -2.784,-7.156 5.863,-16.336c13.242,-14.057 25.478,-16.357 25.478,-16.357c-0,-0 -21.958,-5.129 -45.93,12.892c-6.057,4.553 -29.217,18.836 -28.79,46.158c0.252,16.16 21.145,48.749 57.494,33.639c15.026,-6.246 23.348,-24.522 19.106,-42.717c-4.65,-19.948 -24.975,-28.449 -24.975,-28.449c0,-0 33.741,16.914 17.702,48.236c-11.966,23.367 -40.423,18.98 -51.627,7.776c-20.85,-20.85 -2.806,-45.763 6.811,-54.053c17.883,-15.416 25.306,-16.101 25.306,-16.101c-0,0 -8.565,3.09 -15.764,15.529c-5.202,8.988 9.326,9.783 9.326,9.783Z"
    :style="`fill:${color}`"
  /></g></g></svg>
</template>

<script>
const DEFAULT_COLOR = '#25184e'

export default {
  props: ['journey'],

  computed: {
    color () {
      return DEFAULT_COLOR
    },

    accentLittle () {
      return `${this.journey ? this.journey.color : DEFAULT_COLOR}; opacity: 0.2`
    },

    accentBig () {
      return this.journey ? this.journey.color : `${DEFAULT_COLOR}; opacity: 0.5`
    }
  }
}
</script>
