<template>
  <div
    v-if="journey"
    class="page"
  >
    <router-view
      :app="app"
      :journey="journey"
    />
  </div>
</template>

<script>
const POLL_INTERVAL = 60 * 1000

export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      pollTimer: null
    }
  },

  computed: {
    journey () {
      return this.app.queries.findJourney(this.$route.params.id)
    }
  },

  watch: {
    '$router.currentRoute.value.name' (name) {
      this.redirectIfNeeded()
    },
    journey () {
      this.stopPolling()
      this.poll()
    }
  },

  mounted () {
    this.redirectIfNeeded()
    this.poll()
  },

  unmounted () {
    this.stopPolling()
  },

  methods: {
    redirectIfNeeded () {
      if (this.journey) {
        this.app.setTitle(this.journey.name)
      }

      if (this.$router.currentRoute.value.name === 'journeysShow') {
        this.$router.push(`/journeys/${this.journey.id}/purpose`)
      }
    },

    poll () {
      if (!this.journey) {
        return
      }

      this.app.commands.updateJourneyFromRemote(this.journey)
        .finally(() => {
          this.pollTimer = setTimeout(() => this.poll(), POLL_INTERVAL)
        })
    },

    stopPolling () {
      clearTimeout(this.pollTimer)
    }
  }
}
</script>
