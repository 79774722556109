<template>
  <div class="hint-tip">
    <a
      href="javascript:;"
      class="prompt"
      @click.prevent="toggleTip"
    >
      <span v-if="showTip">&times;</span>
      <span v-else>?</span>
    </a>
    <div
      :class="`tip ${showTip ? 'visible' : ''}`"
    >
      <h3>{{ title }}</h3>
      <div
        class="content"
        v-html="content"
      />
      <button
        class="small"
        @click="hideTip"
      >
        Hide
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No title'
    },
    content: {
      type: String,
      default: 'No content'
    }
  },
  data () {
    return {
      showTip: false
    }
  },
  methods: {
    toggleTip () {
      this.showTip = !this.showTip
    },
    hideTip () {
      this.showTip = false
    }
  }
}
</script>

<style lang="scss">
  .hint-tip {
    position: absolute;
    right: var(--padding);

    .prompt {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      display: inline-block;
      padding: calc(var(--padding) / 2) var(--padding);
      border-radius: 7px;
    }

    .tip {
      position: absolute;
      top: calc(var(--padding) * -1);
      right: calc(var(--padding) * 2.125);
      background: rgba(0, 0, 0, 0.95);
      padding: var(--padding);
      width: 28rem;
      border-radius: 7px;
      opacity: 0.5;
      transition: all 400ms ease-out;
      z-index: -1;
      margin-top: calc(var(--padding) * 2);

      &.visible {
        opacity: 1.0;
        z-index: initial;
        height: auto;
        margin-top: 0;
      }

      @media (max-width: 640px) {
        width: auto;
        position: fixed;
        top: calc(var(--padding) * 5);
        right: var(--padding);
        left: var(--padding);
      }

      h3 {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
        margin-bottom: var(--padding);
      }

      .content {
        color: #fff;
        margin-bottom: var(--padding);
      }

      button {
        float: right;
      }
    }
  }
</style>
