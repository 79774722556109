<template>
  <div>
    <p>
      Media
    </p>
  </div>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  }
}
</script>
