import SORT_BY_NAME from './sorters/sort-by-name.js'
import SORT_BY_TIME from './sorters/sort-by-time.js'
import SORT_BY_ORDER from './sorters/sort-by-order.js'
import SORT_BY_HILLCHART from './sorters/sort-by-hillchart.js'
import SORT_BY_FOCUSCHART from './sorters/sort-by-focuschart.js'
import iconSvg from '../../public/img/icon.svg'
import Quote from './quote.js'

class Queries {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  allJourneys () {
    return this.state.findAll(null, 'journeys')
      .sort(SORT_BY_NAME)
  }

  lastJourney () {
    const journeys = this.state.findAll(null, 'journeys')

    return journeys[journeys.length - 1]
  }

  findJourney (id) {
    return this.state.findById(id, 'journeys', id)
  }

  findAllCheckpointsForJourney (journey) {
    return this.state.findAll(journey.id, 'checkpoints')
  }

  findCheckpointForJourney (journey, checkpointId) {
    return this.findAllCheckpointsForJourney(journey)
      .find((checkpoint) => checkpoint.id === checkpointId)
  }

  findCheckpointForJourneyWithDeletions (journey, checkpointId) {
    return this.state.findAllWithDeleted(journey.id, 'checkpoints', 'withDeletions')
      .find((checkpoint) => checkpoint.id === checkpointId)
  }

  findTaskForJourney (journey, taskid) {
    return this.findAllTasksForJourney(journey)
      .find((task) => task.id === taskid)
  }

  findTaskForJourneyWithDeletions (journey, taskId) {
    return this.state.findAllWithDeleted(journey.id, 'tasks', 'withDeletions')
      .find((task) => task.id === taskId)
  }

  findAllTasksForJourney (journey) {
    return this.state.findAll(journey.id, 'tasks')
  }

  findAllTasksForCheckpoint (journey, checkpointId) {
    return this.findAllTasksForJourney(journey)
      .filter((task) => task.checkpointId === checkpointId)
      .filter((task) => !task.complete)
  }

  findCheckpointNameForJourney (journey, checkpointId) {
    const checkpoint = this.findCheckpointForJourney(journey, checkpointId) || this.findCheckpointForJourneyWithDeletions(journey, checkpointId)

    if (checkpoint) {
      return checkpoint.name
    }

    return '(No reference)'
  }

  findTaskNameForJourney (journey, taskId) {
    const task = this.findTaskForJourney(journey, taskId) || this.findTaskForJourneyWithDeletions(journey, taskId)

    if (task) {
      return task.name
    }

    return '(No reference)'
  }

  isCheckpointCompleteForJourney (journey, checkpoint) {
    return !this.findAllCheckpointCompletionsForJourney(journey, checkpoint).length
  }

  findAllIncompleteCheckpointsForJourney (journey) {
    return this.findAllCheckpointsForJourney(journey)
      .filter((checkpoint) => this.isCheckpointCompleteForJourney(journey, checkpoint))
      .sort(SORT_BY_ORDER)
  }

  findAllCheckpointsForHillchart (journey) {
    return this.findAllIncompleteCheckpointsForJourney(journey)
      .sort(SORT_BY_HILLCHART)
  }

  findAllCheckpointsForFocuschart (journey) {
    return this.findAllIncompleteCheckpointsForJourney(journey)
      .sort(SORT_BY_FOCUSCHART)
  }

  findAllEventsForJourney (journey) {
    return this.state.findAllEvents(journey.id)
  }

  journeyToFile (journey) {
    return this.findAllEventsForJourney(journey)
      .map((event) => `${event.key} ${event.toLocal()}`)
      .join('\n')
  }

  findAllCheckInsForJourney (journey) {
    return this.state.findAll(journey.id, 'checkIns')
      .sort(SORT_BY_TIME)
  }

  findAllCheckpointCompletionsForJourney (journey, checkpoint) {
    return this.state.findAll(journey.id, 'checkpointCompletions')
      .filter((checkpointCompletion) => !checkpoint || checkpoint.id === checkpointCompletion.checkpointId)
      .sort(SORT_BY_TIME)
  }

  findAllTimelineEventsForJourney (journey) {
    return this.findAllCheckpointCompletionsForJourney(journey)
      .concat(this.findAllCheckInsForJourney(journey))
      .sort(SORT_BY_TIME)
  }

  isCheckInACompletion (checkIn) {
    return checkIn.completions && checkIn.completions.length > 0
  }

  prettyDate (date) {
    return new Date(date).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  getLocalData () {
    return this.localAdapter.restore()
  }

  //   getConfigurationData () {
  //     return this.configurationAdapter.restore()
  //   }
  //
  //   findConfiguration (journey) {
  //     return null
  //   }
  //
  //   configurationNameForJourney (journey) {
  //     const configuration = this.findConfiguration(journey)
  //
  //     if (configuration) {
  //       return `${configuration.bucket}/${configuration.key}`
  //     } else {
  //       return 'None'
  //     }
  //   }

  allColors () {
    // '#fc5233', '#1992a9', '#e23176', '#feb80b', '#218646', '#9683CB'
    return ['#A2E8F1', '#F1A2C4', '#A2F1BF', '#FBE7A6', '#FE977C', '#9683CB', '#B8CB83']
  }

  icon (color) {
    return iconSvg
  }

  findQuote () {
    return new Quote()
  }
}

export default Queries
