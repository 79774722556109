<template>
  <form
    class="wrapper"
    aria-label="Add new checkpoint"
    @submit.prevent="addCheckpoint(newCheckpointName)"
  >
    <p class="name">
      <input
        v-model="newCheckpointName"
        aria-label="New checkpoint name"
        type="text"
        placeholder="Add a checkpoint..."
        @keydown.up.prevent="goUp"
      >
    </p>
  </form>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      newCheckpointName: ''
    }
  },

  methods: {
    addCheckpoint (name) {
      this.app.commands.addCheckpointToJourney(this.journey, { name })
      this.newCheckpointName = ''
    },

    goUp (event) {
      const allInputs = [...document.querySelectorAll('input[type="text"]')]
      const currentIndex = allInputs.indexOf(event.target)
      const previousInput = allInputs[currentIndex - 1]

      if (previousInput) {
        previousInput.focus()
        previousInput.setSelectionRange(0, 0)
      }
    }
  }
}
</script>
