import EventStore from './event-store'

export default {
  'checkIns.create': EventStore.RUNNERS.CREATE,
  'checkIns.delete': EventStore.RUNNERS.DELETE,
  'checkpoints.create': EventStore.RUNNERS.CREATE,
  'checkpoints.delete': EventStore.RUNNERS.DELETE,
  'checkpoints.updateOrder' (event) {
    const checkpoint = this.checkpoints.find((item) => item.id === event.objectId)

    if (checkpoint) {
      checkpoint.order = parseInt(event.data)
    }
  },
  'checkpoints.updateHillchart' (event) {
    const checkpoint = this.checkpoints.find((item) => item.id === event.objectId)

    if (checkpoint) {
      checkpoint.hillchart = parseInt(event.data)
    }
  },
  'checkpoints.updateFocuschart' (event) {
    const checkpoint = this.checkpoints.find((item) => item.id === event.objectId)

    if (checkpoint) {
      checkpoint.focuschart = parseInt(event.data)
    }
  },
  'checkpoints.updateName' (event) {
    const checkpoint = this.checkpoints.find((item) => item.id === event.objectId)

    if (checkpoint) {
      checkpoint.name = event.data
    }
  },
  'checkpointCompletions.create': EventStore.RUNNERS.CREATE,
  'checkpointCompletions.delete': EventStore.RUNNERS.DELETE,
  'journeys.create': EventStore.RUNNERS.CREATE,
  'journeys.delete': EventStore.RUNNERS.DELETE,
  'journeys.updateColor' (event) {
    const journey = this.journeys.find((item) => item.id === event.objectId)

    if (journey) {
      journey.color = event.data
    }
  },
  'journeys.updateName' (event) {
    const journey = this.journeys.find((item) => item.id === event.objectId)

    if (journey) {
      journey.name = event.data
    }
  },
  'tasks.create': EventStore.RUNNERS.CREATE,
  'tasks.delete': EventStore.RUNNERS.DELETE,
  'tasks.updateName' (event) {
    const task = this.tasks.find((item) => item.id === event.objectId)

    if (task) {
      task.name = event.data
    }
  },
  'tasks.complete' (event) {
    const task = this.tasks.find((item) => item.id === event.objectId)

    if (task) {
      task.complete = true
    }
  }
}
