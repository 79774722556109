<template>
  <div class="page">
    <header :style="`background: ${bgColor}`">
      <h2>
        Import configuration
      </h2>
    </header>
    <main class="container">
      <p class="warning">
        Don't have a configuration?
        <router-link to="/journeys/new">
          Start here.
        </router-link>
      </p>

      <StorageSettings
        :storage-settings="app.commands.storageSettings"
        :config="storageConfig"
        :save-key="saveKey"
        namespace="journal"
        :on-save="saveStorage"
        :on-verify="verifyData"
        button-text="Import journey"
        :adapters="storageAdapters"
      />
    </main>
  </div>
</template>

<script>
import StorageSettings from '@/storage-settings/component.vue'

export default {
  components: {
    StorageSettings
  },

  props: {
    app: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      bgColor: this.app.queries.allColors()[3],
      storageConfig: {},
      storageAdapters: {
        s3: StorageSettings.Adapters.s3
      },
      saveKey: ''
    }
  },

  mounted () {
    const configuration = (this.$route.params.configuration || '').replace(/\//g, '')

    if (configuration) {
      return this.importFromRaw(configuration)
    }
  },

  methods: {
    verifyData (data) {
      return Promise.resolve(data)
    },

    saveStorage (config) {
      return this.app.commands.importJourneyFromRemote(config)
        .then((journey) => this.$router.push(`/journeys/${journey.id}`))
        .catch((e) => alert(e.message))
    },

    importFromRaw (rawConfig) {
      return this.app.commands.storageSettings
        .parseConfigString(rawConfig)
        .then((config) => this.app.commands.importJourney(config.id, config))
        .then((journey) => this.$router.push(`/journeys/${journey.id}`))
        .catch((e) => alert(e.message))
    }
  }
}
</script>
