<template>
  <li class="task-list-item">
    <Modal
      ref="modal"
      class="inline-block"
    >
      <template #button>
        <input
          type="checkbox"
          @click="newCheckInContent = task.name"
        >
      </template>
      <template #content>
        <h2>Mark task as complete</h2>
        <form @submit.prevent="addCheckIn">
          <div class="field">
            <label>Write a description of what was done to refer to it later.</label>
            <textarea v-model="newCheckInContent" /><br>
          </div>
          <div class="field">
            <label>
              <input
                v-model="newTaskCompletion"
                type="checkbox"
              >
              Completed the task ({{ task.name }})
            </label>
          </div>
          <div class="field">
            <label>
              <input
                v-model="newCheckInCompletion"
                type="checkbox"
              >
              Completed the checkpoint ({{ checkpoint.name }})
            </label>
          </div>
          <div class="actions">
            <button>Check-in</button>
          </div>
        </form>
      </template>
    </Modal>
    <span class="remove-item">
      <span class="hidden">{{ newTaskName }}</span>
      <a
        href="javascript:;"
        class="icon"
        @click="removeTask(task)"
      >
        &times;
      </a>
    </span>
    <input
      v-model="newTaskName"
      type="text"
      class="task-name"
      @keydown.up.prevent="goUp"
      @keydown.down.prevent="goDown"
      @blur="saveTask(task)"
    >
  </li>
</template>

<script>
import Modal from '../modal/component.vue'

export default {
  components: {
    Modal
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    },
    checkpoint: {
      type: Object,
      default () { return {} }
    },
    task: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      newTaskName: this.app.queries.findTaskNameForJourney(this.journey, this.task.id),
      newCheckInContent: '',
      newCheckInCompletion: false,
      newTaskCompletion: true
    }
  },
  methods: {
    goUp (event) {
      const allInputs = [...document.querySelectorAll('input[type="text"]')]
      const currentIndex = allInputs.indexOf(event.target)
      const previousInput = allInputs[currentIndex - 1]

      if (previousInput) {
        previousInput.focus()
        previousInput.setSelectionRange(0, 0)
      }
    },
    goDown () {
      const allInputs = [...document.querySelectorAll('input[type="text"]')]
      const currentIndex = allInputs.indexOf(event.target)
      const nextInput = allInputs[currentIndex + 1]

      if (nextInput) {
        nextInput.focus()
        nextInput.setSelectionRange(0, 0)
      }
    },
    addCheckIn () {
      let time = Date.parse(this.newCheckInTime)

      if (isNaN(time)) {
        time = Date.now()
      }

      const checkIn = {
        content: this.newCheckInContent,
        checkpointIds: [this.checkpoint.id],
        time
      }

      if (this.newCheckInContent.length) {
        this.app.commands.addCheckInToJourney(this.journey, checkIn)
      }

      if (this.newTaskCompletion) {
        this.app.commands.completeTaskForJourney(this.journey, this.task)
      }

      if (this.newCheckInCompletion) {
        this.app.commands.completeCheckpointForJourney(this.journey, this.checkpoint)
      }

      this.newCheckInContent = ''
      this.newCheckInTime = null
      this.newCheckInCompletion = false
      this.newTaskCompletion = false
      this.$refs.modal.close()
    },

    saveTask (task) {
      this.app.commands.updateTaskName(this.journey, task, this.newTaskName)
    },

    removeTask (task) {
      if (confirm('Are you sure you want to remove this task')) {
        this.app.commands.removeTaskFromJourney(this.journey, task)
      }
    }
  }
}
</script>

<style lang="scss">
  .remove-item {
    position: absolute;

    .hidden {
      visibility: hidden;
    }

    .icon {
      position: absolute;
      margin-left: 1rem;
      z-index: 99;
    }
  }

  .task-list-item {
    position: relative;
  }

  .task-name {
    position: absolute;
    z-index: 9;
  }
</style>
