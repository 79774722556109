import EventStoreEvent from '@/state/event-store-event.js'

const STORAGE_NAMESPACE = 'journal'
const JOURNAL_FILE_OPTIONS = (adapterOptions) => {
  return adapterOptions
}

let DEBOUNCED_SAVE

class Commands {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  addJourney (journey) {
    const config = { }
    config[STORAGE_NAMESPACE] = { type: 'none' }

    journey.id = this.state.createDB(null, config)

    this.track(journey, 'journeys', journey.id, 'create', journey)

    this.addCheckpointToJourney(journey, { name: 'Start a journey' })

    const checkpoint = this.queries.findAllCheckpointsForJourney(journey)[0]
    this.completeCheckpointForJourney(journey, checkpoint)
  }

  importJourney (id, config) {
    return new Promise((resolve, reject) => {
      this.storageSettings.verify(config)
        .then(() => this.state.createDB(id))
        .then(() => this.storageSettings.set(id, config, null))
        .then(() => this.updateJourneyFromRemote({ id }))
        .then(() => {
          const journey = this.queries.lastJourney()

          if (!journey) {
            return reject(new Error('No journey found'))
          }

          resolve(journey)
        })
        .catch(reject)
    })
  }

  removeJourney (journey) {
    return Promise.all([
      this.state.deleteDB(journey.id),
      this.storageSettings.remove(journey.id)
    ])
  }

  updateJourneyName (journey, name) {
    this.track(journey, 'journeys', journey.id, 'updateName', name)
  }

  updateJourneyColor (journey, color) {
    this.track(journey, 'journeys', journey.id, 'updateColor', color)
  }

  updateTaskName (journey, task, name) {
    if (this.queries.findTaskNameForJourney(journey, task.id) === name) {
      return
    }

    this.track(journey, 'tasks', task.id, 'updateName', name)
  }

  orderCheckpointsForJourney (journey, checkpointIds) {
    checkpointIds.filter((id) => id !== 'ignore').forEach((checkpointId, index) => {
      const checkpoint = this.queries.findCheckpointForJourney(journey, checkpointId)

      if (checkpoint.order !== index) {
        this.track(journey, 'checkpoints', checkpoint.id, 'updateOrder', index)
      }
    })
  }

  addCheckpointToJourney (journey, checkpoint) {
    checkpoint.journeyId = journey.id

    this.track(journey, 'checkpoints', null, 'create', checkpoint)
  }

  removeCheckpointFromJourney (journey, checkpoint) {
    this.track(journey, 'checkpoints', checkpoint.id, 'delete')
  }

  removeTaskFromJourney (journey, task) {
    this.track(journey, 'tasks', task.id, 'delete')
  }

  completeCheckpointForJourney (journey, checkpoint) {
    this.track(journey, 'checkpointCompletions', null, 'create', { checkpointId: checkpoint.id })
  }

  completeTaskForJourney (journey, task) {
    this.track(journey, 'tasks', task.id, 'complete', true)
  }

  removeCheckpointCompletionFromJourney (journey, checkpointCompletion) {
    this.track(journey, 'checkpointCompletions', checkpointCompletion.id, 'delete')
  }

  addCheckInToJourney (journey, checkIn) {
    this.track(journey, 'checkIns', null, 'create', checkIn)
  }

  removeCheckInFromJourney (journey, checkIn) {
    this.track(journey, 'checkIns', checkIn.id, 'delete')
  }

  addTaskForCheckpoint (journey, task, checkpoint) {
    task.checkpointId = checkpoint.id

    this.track(journey, 'tasks', null, 'create', task)
  }

  track (journey, collectionName, objectId, action, data) {
    this.state.track(journey.id, collectionName, objectId, action, data)
    this.debouncedUpdateJourneySync(journey)
  }

  debouncedUpdateJourneySync (journey) {
    clearTimeout(DEBOUNCED_SAVE)

    DEBOUNCED_SAVE = setTimeout(() => {
      this.syncJourneyWithRemote(journey)
    }, 1000)
  }

  restoreFromLocal () {
    return this.state.restore()
  }

  downloadLogForJourney (journey) {
    const content = this.queries.journeyToFile(journey)
    const blob = this.blob([content], { type: 'application/json;charset=utf-8' })

    this.saveAs(blob, `${journey.name}.txt`)
  }

  importJourneyFromRemote (config) {
    return new Promise((resolve, reject) => {
      this.storageSettings.verify(config)
        .then((data) => {
          let id

          try {
            id = EventStoreEvent.parseEvent(data.split(/\n/)[0]).objectId
          } catch (e) {
            return reject(new Error('Could not parse file.'))
          }

          const fullConfig = {}
          fullConfig[STORAGE_NAMESPACE] = config

          this.importJourney(id, fullConfig)
            .then(resolve)
            .catch(reject)
        })
    })
  }

  updateJourneyFromRemote (journey) {
    return new Promise((resolve, reject) => {
      this.storageSettings
        .fetch(journey.id, JOURNAL_FILE_OPTIONS, STORAGE_NAMESPACE)
        .then((data) => {
          if (typeof data !== 'string') {
            return alert('We unexpectedly received no data when fetching. Check your storage settings.')
          }

          this.state.importRaw(journey.id, data)

          resolve()
        })
        .catch((err) => {
          if (err.constructor.name === 'NoDataError') {
            return resolve()
          }

          reject(err)
        })
    })
  }

  updateJourneyToRemote (journey) {
    return this.storageSettings
      .put(journey.id, this.queries.journeyToFile(journey), JOURNAL_FILE_OPTIONS, STORAGE_NAMESPACE)
  }

  getJourneyConfig (journey) {
    return this.storageSettings
      .get(journey.id, STORAGE_NAMESPACE)
  }

  syncJourneyWithRemote (journey) {
    return this.updateJourneyFromRemote(journey)
      .then(() => this.updateJourneyToRemote(journey))
  }

  copyConfigForJourney (journey) {
    return this.storageSettings
      .getRawConfig(journey.id, STORAGE_NAMESPACE)
      .then((rawConfig) => {
        this.copyToClipboard(rawConfig)
        alert('Copied configuration to clipboard.')
      })
  }

  copyConfigUrlForJourney (journey) {
    return this.storageSettings
      .getRawConfig(journey.id, STORAGE_NAMESPACE)
      .then((rawConfig) => {
        const slashedRawConfig = rawConfig.replace(/.{15}/g, '$&/')
        const url = `${window.location.origin}/journeys/import/${slashedRawConfig}`
        this.copyToClipboard(url)
        alert('Copied configuration URL to clipboard.')
      })
  }

  verifyDataForJourney (journey, data) {
    return new Promise((resolve, reject) => {
      if (!data || !data.length) {
        return resolve()
      }

      return reject(new Error('This configuration is not empty – please empty the file or use the "import a journey" flow.'))
    })
  }

  updateCheckpointHillchart (journey, checkpointId, value) {
    this.track(journey, 'checkpoints', checkpointId, 'updateHillchart', value)
  }

  updateCheckpointFocuschart (journey, checkpointId, value) {
    this.track(journey, 'checkpoints', checkpointId, 'updateFocuschart', value)
  }

  updateCheckpointName (journey, checkpointId, value) {
    if (this.queries.findCheckpointNameForJourney(journey, checkpointId) === name) {
      return
    }

    this.track(journey, 'checkpoints', checkpointId, 'updateName', value)
  }
}

export default Commands
