<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  />
  <main>
    <form
      :aria-label="`Save ${journey.name}`"
      class="container"
      @submit.prevent="submit"
    >
      <div class="field">
        <label for="journey-name">What is the name of the journey?</label>
        <input
          id="journey-name"
          v-model="editableJourney.name"
          type="text"
          aria-label="Journey name"
          placeholder="Name"
          autofocus
          required
        >
      </div>

      <div class="field">
        <label for="journey-name">Choose a color to represent this journey:</label>
        <ul class="colors">
          <li
            v-for="color in COLORS"
            :key="color"
          >
            <a
              href="javascript:;"
              :style="`background: ${color}`"
              :class="`color ${color === editableJourney.color ? 'selected' : ''}`"
              :aria-label="`Journey color ${color}`"
              @click.prevent="editableJourney.color = color"
            />
          </li>
        </ul>
      </div>

      <div class="action">
        <button type="submit">
          Save details
        </button>

        <br>

        <router-link
          :to="`/journeys/${journey.id}/settings/remote`"
          :aria-label="`Storage settings for ${editableJourney.name}`"
        >
          Storage Settings
        </router-link> &nbsp; | &nbsp;

        <a
          href="javascript:;"
          :aria-label="`Copy link to ${journey.name}`"
          @click="app.commands.copyConfigUrlForJourney(journey, 'journal')"
        >
          Copy configuration URL
        <!-- TODO: Only show if configuration exists -->
        </a>

        <br>

        <a
          href="javascript:;"
          :aria-label="`Delete ${journey.name}`"
          @click="removeJourney"
        >Remove this journey</a> &nbsp; | &nbsp;

        <a
          href="javascript:;"
          :aria-label="`Download ${journey.name}`"
          @click="app.commands.downloadLogForJourney(journey)"
        >Download log</a> &nbsp; | &nbsp;

        <router-link
          :to="`/journeys/${journey.id}/audit`"
          :aria-label="`Audit for ${editableJourney.name}`"
        >
          Audit log
        </router-link>
      </div>
    </form>
  </main>
</template>

<script>
import JourneyHeader from '@/app/components/journey-header/component.vue'

export default {
  components: {
    JourneyHeader
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      editableJourney: {},
      COLORS: this.app.queries.allColors()
    }
  },
  computed: {
    storageConfig () {
      return this.journey.storage
    },
    storageDefaults () {
      return {
        s3: {

        }
      }
    }
  },
  mounted () {
    this.editableJourney = {
      id: this.journey.id,
      name: this.journey.name,
      color: this.journey.color || this.COLORS[0]
    }
  },
  methods: {
    submit () {
      if (this.journey.name !== this.editableJourney.name) {
        this.app.commands.updateJourneyName(this.journey, this.editableJourney.name)
      }

      if (this.journey.color !== this.editableJourney.color) {
        this.app.commands.updateJourneyColor(this.journey, this.editableJourney.color)
      }

      this.$router.push(`/journeys/${this.journey.id}`)
    },
    removeJourney () {
      if (confirm('This will remove all data related to this journey. Are you sure you want to delete this journey?')) {
        this.app.commands.removeJourney(this.journey)
          .then(() => {
            this.$router.push('/')
          })
          .catch((err) => alert(err.message))
      }
    }
  }
}
</script>
