<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  />
  <main>
    <div class="container">
      <ul class="list">
        <li
          v-for="timepoint in timepoints"
          :key="timepoint.id"
        >
          <div
            class="timepoint"
            :class="timepoint._collection"
          >
            <div
              v-if="timepoint._collection === 'checkpointCompletions'"
              class="completion"
            >
              <span class="icon">&check;</span>
              <p>"{{ app.queries.findCheckpointNameForJourney(journey, timepoint.checkpointId) }}" completed!</p>
              <p class="date">
                {{ app.queries.prettyDate(timepoint.createdAt) }}
              </p>
              <a
                href="javascript:;"
                :aria-label="`Remove checkpoint completion ${timepoint.id}`"
                class="delete"
                @click="removeCheckpointCompletion(timepoint)"
              >Delete</a>
            </div>
            <div v-else>
              <span
                v-for="checkpointId in timepoint.checkpointIds"
                :key="`timepoint-checkpoint-${checkpointId}`"
                class="tag"
                v-html="app.queries.findCheckpointNameForJourney(journey, checkpointId)"
              />
              <p>{{ timepoint.content }}</p>
              <p class="date">
                {{ app.queries.prettyDate(timepoint.time) }}
              </p>
              <a
                href="javascript:;"
                :aria-label="`Remove check-in ${timepoint.content}`"
                class="delete"
                @click="removeCheckIn(timepoint)"
              >Delete</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import JourneyHeader from '@/app/components/journey-header/component.vue'

export default {
  components: {
    JourneyHeader
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      newCheckInContent: '',
      newCheckInTime: null,
      newCheckInCheckpoint: null,
      newCheckInCompletion: false,
      isStarted: false
    }
  },
  computed: {
    timepoints () {
      return this.app.queries.findAllTimelineEventsForJourney(this.journey)
        .filter((timepoint) => this.app.queries.findCheckpointNameForJourney(this.journey, timepoint.checkpointId))
    },

    incompleteCheckpoints () {
      return this.app.queries.findAllIncompleteCheckpointsForJourney(this.journey)
    }
  },
  methods: {
    addCheckIn () {
      let time = Date.parse(this.newCheckInTime)

      if (isNaN(time)) {
        time = Date.now()
      }

      const checkIn = {
        content: this.newCheckInContent,
        checkpointIds: this.newCheckInCheckpoint ? [this.newCheckInCheckpoint.id] : [],
        time
      }

      if (this.newCheckInContent.length) {
        this.app.commands.addCheckInToJourney(this.journey, checkIn)
      }

      if (this.newCheckInCheckpoint && this.newCheckInCompletion) {
        this.app.commands.completeCheckpointForJourney(this.journey, this.newCheckInCheckpoint)
      }

      this.newCheckInContent = ''
      this.newCheckInTime = null
      this.newCheckInCheckpoint = null
      this.newCheckInCompletion = false
    },

    removeCheckIn (checkIn) {
      if (confirm('Are you sure you want to remove this check-in?')) {
        this.app.commands.removeCheckInFromJourney(this.journey, checkIn)
      }
    },

    removeCheckpointCompletion (checkpointCompletion) {
      if (confirm('Are you sure you want to remove this completion?')) {
        this.app.commands.removeCheckpointCompletionFromJourney(this.journey, checkpointCompletion)
      }
    }
  }
}
</script>
<style lang="scss">
  .new-check-in {
    border-left: 0;
    padding-left: 0;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--half-padding);

    textarea {
      background: var(--bg-color);
      width: 100%;
      min-width: 100%;
      height: 8rem;
      padding: var(--padding);
      resize: none;
      border-top-right-radius: var(--half-padding);
      border-top-left-radius: var(--half-padding);
      font-style: oblique;

      background: #fff;
      border-bottom: 1px solid var(--border-color);
      resize: vertical;
      font-style: normal;
    }

    .actions {
      text-align: right;
      padding: var(--half-padding);

      &:after {
        content: ' ';
        display: block;
        clear: both;
      }

      .options {
        float: left;
        text-align: left;
        margin-top: -0.5rem;

        select {
          margin-bottom: 0.5rem;
          border: 1px solid var(--border-color);
          padding: 0.5rem;
        }

        label {
          display: block;
          font-size: 0.75rem;

          input[type="checkbox"] {
            vertical-align: middle;
            margin-top: -2px;
            margin-right: 0.25rem;
          }
        }
      }

      button {
        display: inline-block;
        width: auto;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 44rem;
    list-style: none !important;
    margin: var(--double-padding) auto;

    @media (max-width: 640px) {
      max-width: 100%;
    }
  }

  .list {
    list-style: none;
    margin-left: var(--padding);

    li {
      border-left: 1px solid var(--border-color);
      padding-left: var(--double-padding);
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: relative;

      &:first-child {
        padding-top: 2rem;
      }

      .timepoint {
        border: 1px solid var(--border-color);
        border-radius: var(--half-padding);
        padding: var(--padding);

        &.checkpointCompletions {
          background: #eefff8;
          border: 2px solid #54e684;
          font-weight: 500;
          color: var(--text-color);

          .icon {
            position: absolute;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
            line-height: 1;
            padding-top: 0.3rem;
            font-size: 1.25rem;
            top: 50%;
            margin-top: -1rem;
            left: -1rem;
            border: 2px solid #54e684;
            background: #eefff8;
            color: #54e684;
          }
        }

        .tag {
          background: var(--bg-color);
          padding: var(--half-padding);
          display: inline-block;
          border-radius: var(--half-padding);
          line-height: 1;
          font-size: 0.75rem;
          float: right;
          margin-left: 1rem;
        }

        .date {
          font-size: 0.75rem;
          font-style: oblique;
          opacity: 0.5;
          margin-top: var(--half-padding);
        }
      }

      &:before {
        content: ' ';
        display: block;
        border-top: 1px solid var(--border-color);
        width: 2rem;
        position: absolute;
        margin-left: -2rem;
        top: 50%;
      }

      .delete {
        position: absolute;
        right: 0.5rem;
        bottom: 1.5rem;
        font-size: 0.75rem;
        opacity: 0.25;
        line-height: 1;
      }
    }
  }
</style>
