<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  />
  <main>
    <table>
      <thead>
        <th>Event</th>
        <th>Data</th>
      </thead>
      <tbody>
        <tr
          v-for="event in app.queries.findAllEventsForJourney(journey)"
          :key="event.key"
        >
          <td>
            <strong>
              {{ event.collection }}#{{ event.objectId }}
            </strong>
          </td>
          <td>
            {{ event.action }}
          </td>
          <td>
            {{ event.date }}
          </td>
          <td>
            <pre v-html="yaml(event.data)" />
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import JourneyHeader from '@/app/components/journey-header/component.vue'

export default {
  components: {
    JourneyHeader
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },

  methods: {
    yaml (data) {
      if (typeof data === 'string' || typeof data === 'number') {
        return data
      }

      let html = ''

      for (const key in data) {
        html += `${key}: ${data[key]}\n`
      }

      return html
    }
  }
}
</script>
