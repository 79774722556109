<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  />
  <main>
    <div class="container">
      <StorageSettings
        v-if="storageConfig"
        :storage-settings="app.commands.storageSettings"
        :config="storageConfig"
        :save-key="journey.id"
        namespace="journal"
        :on-save="saveStorage"
        :on-verify="verifyData"
        :adapters="storageAdapters"
      />
    </div>
  </main>
</template>

<script>
import StorageSettings from '@/storage-settings/component.vue'
import Adapters from '@/storage-settings/adapters/index.js'
import JourneyHeader from '@/app/components/journey-header/component.vue'

export default {
  components: {
    JourneyHeader,
    StorageSettings
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      storageConfig: null,
      storageAdapters: Adapters
    }
  },
  mounted () {
    this.app.commands.getJourneyConfig(this.journey)
      .then((config) => {
        this.storageConfig = config || {}
      })
  },
  methods: {
    verifyData (data) {
      return this.app.commands.verifyDataForJourney(this.journey, data)
    },

    saveStorage (config) {
      return this.app.commands.syncJourneyWithRemote(this.journey)
        .then(() => this.$router.push(`/journeys/${this.journey.id}`))
        .catch((e) => alert(e.message))
    }
  }
}
</script>
