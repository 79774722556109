import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/app/views/home/component.vue'
import JourneysImport from '@/app/views/journeys/import.vue'
import JourneysNew from '@/app/views/journeys/new.vue'
import JourneysShow from '@/app/views/journeys/show.vue'
import JourneysMedia from '@/app/views/journeys/media.vue'
import JourneysSettings from '@/app/views/journeys/settings.vue'
import JourneysSettingsRemote from '@/app/views/journeys/settings-remote.vue'
import ProgressIndex from '@/app/views/progress/index.vue'
import PurposeIndex from '@/app/views/purpose/index.vue'
import PlanIndex from '@/app/views/plan/index.vue'
import AuditIndex from '@/app/views/audit/index.vue'

const routes = [
  {
    path: '/journeys/:id',
    name: 'journeysShow',
    component: JourneysShow,
    children: [
      {
        path: '/journeys/:id/settings',
        name: 'journeysSettings',
        component: JourneysSettings
      },
      {
        path: '/journeys/:id/settings/remote',
        name: 'journeysSettingsRemote',
        component: JourneysSettingsRemote
      },
      {
        path: '/journeys/:id/media',
        name: 'journeysMedia',
        component: JourneysMedia
      },
      {
        path: '/journeys/:id/progress',
        name: 'ProgressIndex',
        component: ProgressIndex
      },
      {
        path: '/journeys/:id/plan',
        name: 'PlanIndex',
        component: PlanIndex
      },
      {
        path: '/journeys/:id/purpose',
        name: 'PurposeIndex',
        component: PurposeIndex
      },
      {
        path: '/journeys/:id/audit',
        name: 'auditIndex',
        component: AuditIndex
      }
    ]
  },
  {
    path: '/journeys/new',
    name: 'journeysNew',
    component: JourneysNew
  },
  {
    path: '/journeys/import:configuration(.*)',
    name: 'journeysImport',
    component: JourneysImport
  },
  {
    path: '/',
    name: 'home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior () {
    return { top: 0, behavior: 'smooth' }
  },
  routes
})

export default router

export {
  routes
}
