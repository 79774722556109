<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  >
    <HintTip
      title="Use this focus chart to prioritize."
      content="Drag and drop the `dots` on the chart to lay out your current focus and identify where you'd like to be."
    />
    <FocusChart
      v-if="showChart"
      :options="{
        width: chartWidth,
        height: 500,
        padding: 30,
        circleRadius: 15,
        lineWidth: lineWidth,
        lineColor: journey.color,
        circleColors: [journey.color],
        labelColors: ['#25184e'],
        labelSize: '0.85em',
        hillLabelColor: 'rgba(0, 0, 0, 0.2)',
      }"
      :data="journeyCheckpointsToFocuschart"
      :on-update="updateCheckpointFocuschart"
    />
  </JourneyHeader>
  <main>
    <div class="container">
      <TaskListForCheckpoint
        v-for="(checkpoint, index) in focuschartCheckpoints"
        :key="`task-list-${checkpoint.id}`"
        :app="app"
        :journey="journey"
        :checkpoint="checkpoint"
        :opacity="(focuschartCheckpoints.length - index) / focuschartCheckpoints.length"
        :show-items="false"
      />

      <AddCheckpoint
        :app="app"
        :journey="journey"
      />
    </div>
  </main>
</template>

<script>
import AddCheckpoint from '@/app/components/add-checkpoint/component.vue'
import FocusChart from '@/app/components/focus-chart/component.vue'
import HintTip from '@/app/components/hint-tip/component.vue'
import JourneyHeader from '@/app/components/journey-header/component.vue'
import SORT_BY_NAME from '@/queries/sorters/sort-by-name.js'
import TaskListForCheckpoint from '../../components/task-list-for-checkpoint/component.vue'

export default {
  components: {
    AddCheckpoint,
    FocusChart,
    HintTip,
    JourneyHeader,
    TaskListForCheckpoint
  },

  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },

  computed: {
    focuschartCheckpoints () {
      return this.app.queries.findAllCheckpointsForFocuschart(this.journey)
    },

    journeyCheckpointsToFocuschart () {
      return this.focuschartCheckpoints.sort(SORT_BY_NAME).map((checkpoint, index) => {
        return {
          id: checkpoint.id,
          title: checkpoint.name,
          color: this.journey.color,
          value: checkpoint.focuschart || 1
        }
      })
    },

    showChart () {
      return this.journeyCheckpointsToFocuschart.length > 2
    },

    chartWidth () {
      return window.outerWidth > 640 ? window.outerWidth * 0.7 : window.outerWidth * 0.8
    },

    lineWidth () {
      return window.outerWidth > 640 ? 1 : 1
    }
  },

  methods: {
    updateCheckpointFocuschart (chartCheckpoint, value) {
      this.app.commands.updateCheckpointFocuschart(this.journey, chartCheckpoint.id, value)
    }
  }
}
</script>
