<template>
  <div class="page">
    <header :style="`background: ${journey.color}`">
      <h2>
        Begin a new journey
      </h2>
    </header>
    <main class="container">
      <p class="warning">
        Already have a journey?
        <router-link
          to="/journeys/import"
          aria-label="Import a journey"
        >
          Import it here.
        </router-link>
      </p>
      <form
        aria-label="Start journey"
        @submit.prevent="submit"
      >
        <div class="field">
          <label for="journey-name">What is the name of the journey?</label>
          <input
            id="journey-name"
            v-model="journey.name"
            type="text"
            aria-label="Journey name"
            placeholder="Name"
            autofocus
            required
          >
        </div>

        <div class="field">
          <label for="journey-name">Choose a color to represent this journey:</label>
          <ul class="colors">
            <li
              v-for="color in COLORS"
              :key="color"
            >
              <a
                href="javascript:;"
                :style="`background: ${color}`"
                :class="`color ${color === journey.color ? 'selected' : ''}`"
                :aria-label="`Journey color ${color}`"
                @click.prevent="journey.color = color"
              />
            </li>
          </ul>
        </div>

        <div class="action">
          <button type="submit">
            Start journey
          </button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      journey: {},
      COLORS: this.app.queries.allColors()
    }
  },
  mounted () {
    this.journey = {
      color: this.COLORS[0]
    }
  },
  methods: {
    submit () {
      this.app.commands.addJourney(this.journey)

      const journey = this.app.queries.lastJourney()

      this.$router.push(`/journeys/${journey.id}`)
    }
  }
}
</script>

<style lang="scss">
  .colors {
    li {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .color {
      display: block;
      width: 2.75rem;
      height: 2.75rem;

      &.selected {
        outline: 2px dotted #777;
      }
    }
  }
</style>
