<template>
  <header
    :style="headerStyle"
    class="no-select"
  >
    <h2>
      <router-link
        :to="`/journeys/${journey.id}`"
      >
        {{ journey.name }}
      </router-link>
    </h2>
    <div class="subnav">
      <ul>
        <li>
          <router-link
            :to="`/journeys/${journey.id}/purpose`"
            :aria-label="`See purpose for ${journey.name}`"
          >
            <img
              src="./purpose.svg"
            >
            Purpose
          </router-link>
        </li>
        <li>
          <router-link
            :to="`/journeys/${journey.id}/plan`"
            :aria-label="`See plan for ${journey.name}`"
          >
            <img
              src="./plan.svg"
            >
            Plan
          </router-link>
        </li>
        <li>
          <router-link
            :to="`/journeys/${journey.id}/progress`"
            :aria-label="`See timeline for ${journey.name}`"
          >
            <img
              src="./progress.svg"
            >
            Progress
          </router-link>
        </li>
        <li>
          <router-link
            :to="`/journeys/${journey.id}/settings`"
            :aria-label="`Edit ${journey.name}`"
          >
            Setup
          </router-link>
        </li>
      </ul>
    </div>

    <slot />
  </header>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },
  computed: {
    headerStyle () {
      const match = (this.journey.color || '#25184e').replace(/^#/, '').match(/.{1,2}/g)
      const rgb = [
        parseInt(match[0], 16),
        parseInt(match[1], 16),
        parseInt(match[2], 16)
      ]

      return `
        border-bottom-color: ${this.journey.color};
        background: rgb(${rgb[0]},${rgb[1]},${rgb[2]});
        background: linear-gradient(0deg, rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.1) 10%, rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.7) 100%);
      `
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    img {
      width: 1.5rem;
      vertical-align: middle;
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
      margin-right: var(--half-padding);
    }
  }
</style>
