<template>
  <div ref="focuschart" />
</template>

<script>
class FocusChart {
  constructor (config) {
    this.listeners = {}
    this.options = config.options || {}
    this.options.width = this.options.width || 1000
    this.options.height = this.options.height || 500
    this.options.padding = this.options.padding || 20
    this.options.circleRadius = this.options.circleRadius || 10
    this.options.lineWidth = this.options.lineWidth || 3
    this.options.lineColor = this.options.lineColor || '#333'
    this.options.labelSize = this.options.labelSize || '1em'
    this.maxDimension = Math.min(this.options.width, this.options.height)
    this.data = config.data || []
    this.circleColorIndex = 0
    this.labelColorIndex = 0
    this.MAX_VALUE = 5
    this.WEB_VALUES = [1, 2, 3, 4, this.MAX_VALUE]
  }

  createSvg () {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

    svg.setAttribute('version', '1.1')
    svg.setAttribute('viewBox', `0 0 ${this.options.width} ${this.options.height}`)

    return svg
  }

  buildSvg () {
    if (this.data.length < 3) {
      return
    }

    this.WEB_VALUES.forEach((value) => {
      const web = document.createElementNS('http://www.w3.org/2000/svg', 'polyline')
      web.setAttribute('stroke', this.options.hillLabelColor)
      web.setAttribute('stroke-dasharray', '5,5')
      web.setAttribute('style', `stroke-width: ${this.options.lineWidth}px; fill: none`)
      const points = []
      this.data.concat([{}]).forEach((item, index) => {
        const coords = this.coordinatesForValueAtIndex(index, value)

        points.push(`${coords[0]} ${coords[1]}`)
      })

      web.setAttribute('points', points.join(' '))
      this.svg.appendChild(web)
    })

    const areaWeb = document.createElementNS('http://www.w3.org/2000/svg', 'polyline')
    const areaWebColor = this.nextCircleColor()
    areaWeb.setAttribute('stroke', areaWebColor)
    areaWeb.setAttribute('style', `stroke-width: ${this.options.lineWidth}px; opacity: 0.5; fill: ${areaWebColor}`)
    this.svg.appendChild(areaWeb)
    this.updateAreaWeb(areaWeb)

    this.data.forEach((point, index) => {
      const color = point.color || this.nextCircleColor()

      const coords = this.coordinatesForValueAtIndex(index, this.MAX_VALUE)
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line')
      line.setAttribute('x1', coords[0])
      line.setAttribute('y1', coords[1])
      line.setAttribute('x2', this.options.width / 2)
      line.setAttribute('y2', this.options.height / 2)
      line.setAttribute('stroke', this.options.hillLabelColor)
      line.setAttribute('stroke-width', `${this.options.lineWidth / 2}px`)
      this.svg.appendChild(line)

      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
      circle.setAttribute('cursor', 'pointer')
      circle.setAttribute('r', this.options.circleRadius)
      circle.setAttribute('fill', color)

      const label = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      label.setAttribute('fill', this.nextTextColor() || point.color)
      label.setAttribute('font-size', this.options.labelSize)
      const labelText = document.createTextNode(point.title)
      label.appendChild(labelText)

      this.setPointPosition(circle, label, index, point.value)

      let lastValue

      const setCirclePos = (x, y) => {
        const value = this.findClosestValueToPoint(x, y, index)

        if (value) {
          lastValue = value
          this.setPointPosition(circle, label, index, value)
          this.updateAreaWeb(areaWeb)
          this.emit('drag', point, value)
        }
      }

      const handleMouseMove = (e) => {
        e.preventDefault()

        const offsetX = e.touches ? e.touches[0].clientX : e.clientX
        const offsetY = e.touches ? e.touches[0].clientY : e.clientY

        setCirclePos(offsetX - this.svg.getBoundingClientRect().left, offsetY - this.svg.getBoundingClientRect().top)
      }

      const handleMouseUp = (e) => {
        if (typeof lastValue !== 'undefined') {
          this.emit('update', point, lastValue)
        }

        this.svg.removeEventListener('mousemove', handleMouseMove)
        this.svg.removeEventListener('mouseup', handleMouseUp)
        this.svg.removeEventListener('touchend', handleMouseUp)
        this.svg.removeEventListener('touchmove', handleMouseMove)
      }

      const handleMouseDown = (e) => {
        this.svg.addEventListener('mousemove', handleMouseMove)
        this.svg.addEventListener('mouseup', handleMouseUp)
        this.svg.addEventListener('touchend', handleMouseUp)
        this.svg.addEventListener('touchmove', handleMouseMove)
      }

      circle.addEventListener('mousedown', handleMouseDown)
      circle.addEventListener('touchstart', handleMouseDown)

      this.svg.appendChild(circle)
      this.svg.appendChild(label)
    })
  }

  on (eventName, func) {
    this.listeners[eventName] = (this.listeners[eventName] instanceof Array) ? this.listeners[eventName] : []
    this.listeners[eventName].push(func)
  }

  off (eventName, func) {
    if (!eventName) {
      this.listeners = {}
      return
    }

    const listeners = (this.listeners[eventName] || [])
    const index = listeners.indexOf(func)

    listeners.splice(index, 1)
  }

  emit (eventName, ...args) {
    const listeners = (this.listeners[eventName] || [])

    listeners.forEach((listenerFunc) => listenerFunc(...args))
  }

  coordinatesForValueAtIndex (index, value) {
    const itemsLength = this.data.length
    const xCentre = this.options.width / 2
    const yCentre = this.options.height / 2
    const padding = this.options.padding
    const radius = ((this.maxDimension - padding - padding) / 2) * (value / this.MAX_VALUE)
    const angleIncrement = (2 * Math.PI) / itemsLength
    const angleInRadians = -Math.PI / 2 + index * angleIncrement
    const x = xCentre + radius * Math.cos(angleInRadians)
    const y = yCentre + radius * Math.sin(angleInRadians)

    return [x, y]
  }

  setPointPosition (circle, label, index, value) {
    const circleCoords = this.coordinatesForValueAtIndex(index, value)

    circle.setAttribute('cx', circleCoords[0])
    circle.setAttribute('cy', circleCoords[1])

    const tolerance = this.maxDimension / 10
    const labelCoords = this.coordinatesForValueAtIndex(index, value + 1)
    const extremeTop = circleCoords[1] - tolerance < 0
    const extremeBottom = circleCoords[1] + tolerance > this.options.height
    label.setAttribute('x', labelCoords[0])

    if (extremeTop) {
      label.setAttribute('y', labelCoords[1] + (this.options.padding * 3))
    } else if (extremeBottom) {
      label.setAttribute('y', labelCoords[1] - (this.options.padding * 3))
    } else {
      label.setAttribute('y', labelCoords[1])
    }

    const isLeftThird = labelCoords[0] + tolerance < this.options.width / 2
    const isRightThird = labelCoords[0] > this.options.width / 2

    if (isLeftThird) {
      label.setAttribute('text-anchor', 'end')
    } else if (isRightThird) {
      label.setAttribute('text-anchor', 'start')
    } else {
      label.setAttribute('text-anchor', 'middle')
    }

    label.setAttribute('dominant-baseline', 'middle')
  }

  updateAreaWeb (areaWeb) {
    const points = []
    this.data.forEach((item, index) => {
      const coords = this.coordinatesForValueAtIndex(index, item.value)

      points.push(`${coords[0]} ${coords[1]}`)
    })

    areaWeb.setAttribute('points', points.join(' '))
  }

  findClosestValueToPoint (x, y, index) {
    let closestValue = null
    let closestDelta = null

    const realWidth = this.svg.clientWidth
    const ratio = realWidth / this.options.width

    this.WEB_VALUES.map((value) => {
      const coords = this.coordinatesForValueAtIndex(index, value)
      const deltaX = Math.abs(x - (coords[0] * ratio))
      const deltaY = Math.abs(y - (coords[1] * ratio))
      const delta = deltaX + deltaY

      if (!closestDelta || delta < closestDelta) {
        closestValue = value
        closestDelta = delta
      }
    })

    return closestValue
  }

  nextCircleColor () {
    const color = this.options.circleColors[this.circleColorIndex]

    if (color) {
      this.circleColorIndex++
      return color
    } else {
      this.circleColorIndex = 1
      return this.options.circleColors[0]
    }
  }

  nextTextColor () {
    const color = this.options.labelColors[this.labelColorIndex]

    if (color) {
      this.labelColorIndex++
      return color
    } else {
      this.labelColorIndex = 1
      return this.options.labelColors[0]
    }
  }

  mount (el) {
    this.svg = this.createSvg()

    el.innerHTML = ''
    el.appendChild(this.svg)

    this.buildSvg()
  }
}

export default {
  props: {
    options: {
      type: Object,
      default () { return {} }
    },
    data: {
      type: Array,
      default () { return [] }
    },
    onSlide: {
      type: Function,
      default () { return () => {} }
    },
    onUpdate: {
      type: Function,
      default () { return () => {} }
    }
  },
  watch: {
    data (val) {
      this.buildFocuschart()
    }
  },
  mounted () {
    this.buildFocuschart()
  },
  methods: {
    buildFocuschart () {
      if (!this.$refs.focuschart) {
        return
      }

      this.focuschart = new FocusChart({
        options: this.options,
        data: this.data
      })

      this.focuschart.on('drag', (point, percentage) => {
        if (typeof this.onDrag === 'function') {
          this.onDrag(point, percentage)
        }
      })

      this.focuschart.on('update', (point, percentage) => {
        if (typeof this.onUpdate === 'function') {
          this.onUpdate(point, percentage)
        }
      })

      this.focuschart.mount(this.$refs.focuschart)
    }
  }
}
</script>
