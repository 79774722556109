<template>
  <div ref="hillchart" />
</template>

<script>
class HillChart {
  constructor (config) {
    this.listeners = {}
    this.options = config.options || {}
    this.options.width = this.options.width || 1000
    this.options.height = this.options.height || (this.options.width / 4)
    this.options.padding = this.options.padding || 20
    this.options.circleRadius = this.options.circleRadius || 10
    this.options.lineWidth = this.options.lineWidth || 3
    this.options.lineColor = this.options.lineColor || '#333'
    this.options.labelSize = this.options.labelSize || '1em'
    this.data = config.data || []
    this.circleColorIndex = 0
    this.labelColorIndex = 0
  }

  createElement (parentNode) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('version', '1.1')
    svg.setAttribute('viewBox', `0 0 ${this.options.width} ${this.options.height}`)
    this.svg = svg
    parentNode.innerHTML = ''
    parentNode.appendChild(svg)

    const hill = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    hill.setAttribute('d', `M${this.options.padding},${this.options.height} Q${this.options.width / 2},-${this.options.height - this.options.padding} ${this.options.width - this.options.padding},${this.options.height}`)
    hill.setAttribute('vector-effect', 'non-scaling-stroke')
    hill.setAttribute('style', `stroke: ${this.options.lineColor}; stroke-width: ${this.options.lineWidth}px; fill: none`)
    svg.appendChild(hill)

    if (this.options.uphillLabelText) {
      const uphillLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      uphillLabel.setAttribute('fill', this.options.hillLabelColor)
      uphillLabel.setAttribute('font-size', this.options.labelSize)
      const uphillLabelText = document.createTextNode(this.options.uphillLabelText)
      uphillLabel.appendChild(uphillLabelText)
      uphillLabel.setAttribute('x', this.options.width * 0.43)
      uphillLabel.setAttribute('y', this.options.height - (this.options.padding))
      uphillLabel.setAttribute('text-anchor', 'end')
      svg.appendChild(uphillLabel)
    }

    if (this.options.downhillLabelText) {
      const downhillLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      downhillLabel.setAttribute('fill', this.options.hillLabelColor)
      downhillLabel.setAttribute('font-size', this.options.labelSize)
      const downhillLabelText = document.createTextNode(this.options.downhillLabelText)
      downhillLabel.appendChild(downhillLabelText)
      downhillLabel.setAttribute('x', this.options.width * 0.57)
      downhillLabel.setAttribute('y', this.options.height - (this.options.padding))
      downhillLabel.setAttribute('text-anchor', 'start')
      svg.appendChild(downhillLabel)
    }

    if (this.options.uphillLabelText || this.options.downhillLabelText) {
      const divider = document.createElementNS('http://www.w3.org/2000/svg', 'line')
      divider.setAttribute('x1', this.options.width / 2)
      divider.setAttribute('y1', this.options.padding / 2)
      divider.setAttribute('x2', this.options.width / 2)
      divider.setAttribute('y2', this.options.height)
      divider.setAttribute('stroke', this.options.hillLabelColor)
      divider.setAttribute('stroke-dasharray', '5,5')
      divider.setAttribute('stroke-width', `${this.options.lineWidth / 2}px`)
      svg.appendChild(divider)
    }

    this.data.reverse().forEach((point) => {
      const color = point.color || this.nextCircleColor()

      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
      circle.setAttribute('cursor', 'pointer')
      circle.setAttribute('r', this.options.circleRadius)
      circle.setAttribute('fill', color)

      const label = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      label.setAttribute('fill', this.nextTextColor() || point.color)
      label.setAttribute('font-size', this.options.labelSize)
      const labelText = document.createTextNode(point.title)
      label.appendChild(labelText)

      this.setPointPosition(circle, label, point.value)

      let lastPercentage

      const setCirclePos = (pixels) => {
        lastPercentage = this.calculatePercentageFromPixels(pixels)

        this.setPointPosition(circle, label, lastPercentage)
        this.emit('drag', point, lastPercentage)
      }

      const handleMouseMove = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()

        const offsetX = e.touches ? e.touches[0].clientX : e.clientX

        setCirclePos(offsetX - svg.getBoundingClientRect().left)
      }

      const handleMouseUp = (e) => {
        if (typeof lastPercentage !== 'undefined') {
          this.emit('update', point, lastPercentage)
        }

        document.body.removeEventListener('mousemove', handleMouseMove, { passive: false })
        document.body.removeEventListener('mouseup', handleMouseUp, { passive: false })
        document.body.removeEventListener('touchend', handleMouseUp, { passive: false })
        document.body.removeEventListener('touchmove', handleMouseMove, { passive: false })
      }

      const handleMouseDown = (e) => {
        document.body.addEventListener('mousemove', handleMouseMove, { passive: false })
        document.body.addEventListener('mouseup', handleMouseUp, { passive: false })
        document.body.addEventListener('touchend', handleMouseUp, { passive: false })
        document.body.addEventListener('touchmove', handleMouseMove, { passive: false })
      }

      circle.addEventListener('mousedown', handleMouseDown)
      circle.addEventListener('touchstart', handleMouseDown)

      svg.appendChild(circle)
      svg.appendChild(label)
    })

    return svg
  }

  on (eventName, func) {
    this.listeners[eventName] = (this.listeners[eventName] instanceof Array) ? this.listeners[eventName] : []
    this.listeners[eventName].push(func)
  }

  off (eventName, func) {
    if (!eventName) {
      this.listeners = {}
      return
    }

    const listeners = (this.listeners[eventName] || [])
    const index = listeners.indexOf(func)

    listeners.splice(index, 1)
  }

  emit (eventName, ...args) {
    const listeners = (this.listeners[eventName] || [])

    listeners.forEach((listenerFunc) => listenerFunc(...args))
  }

  setPointPosition (circle, label, percentage) {
    if (percentage < 2) {
      percentage = 2
    } else if (percentage > 98) {
      percentage = 98
    }

    const x = this.calculateXFromPercentage(percentage)
    const y = this.calculateYFromPercentage(percentage)

    circle.setAttribute('cx', x)
    circle.setAttribute('cy', y)

    if (percentage < 20) {
      label.setAttribute('x', x + (this.options.circleRadius * 1.5))
      label.setAttribute('y', y + (this.options.circleRadius / 2))
    } else if (percentage > 80) {
      label.setAttribute('x', x - (this.options.circleRadius * 1.5))
      label.setAttribute('y', y + (this.options.circleRadius / 2))
    } else {
      label.setAttribute('x', x)
      label.setAttribute('y', y + (this.options.circleRadius * 2))
    }

    if (percentage < 35) {
      label.setAttribute('text-anchor', 'start')
    } else if (percentage > 65) {
      label.setAttribute('text-anchor', 'end')
    } else {
      label.setAttribute('text-anchor', 'middle')
    }
  }

  calculatePercentageFromPixels (pixels) {
    const realWidth = this.svg.clientWidth
    const ratio = realWidth / this.options.width
    const ratioedPadding = this.options.padding * ratio
    const percentage = Math.round((pixels - ratioedPadding) / (realWidth - ratioedPadding * 2) * 100)
    // const stepped = Math.ceil(percentage / 5) * 5

    return Math.max(Math.min(percentage, 100), 0)
  }

  calculateXFromPercentage (percentage) {
    const realWidth = this.svg.clientWidth
    const ratio = realWidth / this.options.width
    const ratioedPadding = this.options.padding * ratio
    return ((percentage / 100 * (this.options.width - 2 * ratioedPadding)) + ratioedPadding)
  }

  calculateYFromPercentage (percentage) {
    const realWidth = this.svg.clientWidth
    const ratio = realWidth / this.options.width
    const ratioedPadding = this.options.padding * ratio
    const x = this.calculateXFromPercentage(percentage)
    const mysteryFactor = realWidth > 640 ? ratioedPadding / 1.5 : ratioedPadding * 3
    const a = (this.options.height + mysteryFactor) / ((this.options.width) / 2) ** 2
    const b = -a * (this.options.width - 2 * ratioedPadding) / 2

    return a * (x - this.options.width / 2) ** 2 + b + (this.options.circleRadius)
  }

  nextCircleColor () {
    const color = this.options.circleColors[this.circleColorIndex]

    if (color) {
      this.circleColorIndex++
      return color
    } else {
      this.circleColorIndex = 1
      return this.options.circleColors[0]
    }
  }

  nextTextColor () {
    const color = this.options.labelColors[this.labelColorIndex]

    if (color) {
      this.labelColorIndex++
      return color
    } else {
      this.labelColorIndex = 1
      return this.options.labelColors[0]
    }
  }

  mount (el) {
    this.el = this.createElement(el)
  }
}

export default {
  props: {
    options: {
      type: Object,
      default () { return {} }
    },
    data: {
      type: Array,
      default () { return [] }
    },
    onSlide: {
      type: Function,
      default () { return () => {} }
    },
    onUpdate: {
      type: Function,
      default () { return () => {} }
    }
  },
  watch: {
    data (val) {
      this.buildHillchart()
    }
  },
  mounted () {
    this.buildHillchart()
  },
  methods: {
    buildHillchart () {
      if (!this.$refs.hillchart) {
        return
      }

      this.hillchart = new HillChart({
        options: this.options,
        data: this.data
      })

      this.hillchart.on('drag', (point, percentage) => {
        if (typeof this.onDrag === 'function') {
          this.onDrag(point, percentage)
        }
      })

      this.hillchart.on('update', (point, percentage) => {
        if (typeof this.onUpdate === 'function') {
          this.onUpdate(point, percentage)
        }
      })

      this.hillchart.mount(this.$refs.hillchart)
    }
  }
}
</script>
