<template>
  <div class="modal">
    <a
      href="javascript:;"
      :class="buttonClass"
      @click.prevent="open"
    >
      <slot name="button" />
    </a>
    <div
      v-if="isOpen"
      class="page-wrapper"
    >
      <a
        href="javascript:;"
        class="page-overlay animate__animated animate__fadeIn"
        @click.prevent="close"
      />
      <div class="page animate__animated animate__fadeInUp animate__faster">
        <slot
          name="content"
          v-bind="{ close }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'

export default {
  props: {
    buttonClass: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  mounted () {
    this.isOpen = false
  },
  methods: {
    open () {
      this.isOpen = true

      nextTick(() => {
        const firstElement = this.$el.querySelectorAll('.page textarea, .page input')[1]

        if (firstElement) {
          firstElement.focus()
        }
      })
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss">
  .modal {
    .page-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999999999;

      .page {
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        padding: var(--padding);
        border-radius: var(--border-radius);
        z-index: 100;
        width: 100%;
        max-width: 480px;
        margin: 10vh auto;
        max-height: 80vh;
        min-height: auto;
        position: relative;
      }
    }

    .page-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }
</style>
