<template>
  <span :class="className">{{ text }}</span>
</template>

<script>
const POLL_INTERVAL = 2000

export default {
  props: ['contents'],

  data () {
    return {
      intervals: [],
      currentIndex: -1,
      className: ''
    }
  },

  computed: {
    text () {
      return this.contents[this.currentIndex]
    }
  },

  mounted () {
    this.cycle()
  },

  unmounted () {
    this.intervals.forEach((interval) => clearInterval(interval))
  },

  methods: {
    cycle () {
      this.className = 'animate__animated animate__fadeIn'

      if (this.currentIndex < this.contents.length - 1) {
        this.currentIndex += 1
      } else {
        this.currentIndex = 0
      }

      this.intervals = [
        setTimeout(() => { this.className = '' }, POLL_INTERVAL / 2),
        setTimeout(() => this.cycle(), POLL_INTERVAL)
      ]
    }
  }
}
</script>
