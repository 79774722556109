<template>
  <div class="page">
    <main>
      <div class="prose">
        <h1 class="centered">
          How do you know if you're doing a good job as a <TextCycle :contents="['person', 'parent', 'child', 'friend', 'brother', 'sister']" />?
        </h1>

        <p>
          <strong>It's often really hard to know if you're doing the right thing.</strong>
          And it's so easy to let the chaos in.
          Your attention becomes scattered among calls, messages, work, family, school, community, and more.
          And as it does, your good intentions and dreams fade...
        </p>

        <p>
          There's a secret that "they" probably forgot to tell you – it's in the long lost <em>Manual for Life&trade;</em>.
          If you don't know about it yet, that's okay.
          In about one-sentence's time, you too will be in-the-know.
          Here it is:
        </p>

        <!-- <div
          class="aspect-ratio prose-block"
        >
          <iframe
            src="https://www.youtube-nocookie.com/embed/-97jhZOmcm0?controls=0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div> -->

        <h2>
          <a>Progress</a> is the source of happiness and success.
        </h2>

        <p>
          Once upon a time, you had big dreams about your future &mdash; and big dreams for your relationships.
          But, as all of us do, you got lost.
          You settled in and stopped growing.
          You stopped creating.
          You stopped <em>progressing</em>.
        </p>

        <p>
          Now, think back to a time when you felt really great.
          Were you learning something new?
          Moving toward a goal?
          Stepping out of your comfort zone?
          Almost certainly.
        </p>

        <p>
          Simply "moving" can turn your world upside down (in a good way).
          It doesn't matter if you're going fast or slow, forward or backward, crawling or running.
          Most importantly, if you can steer your movement <em>towards something meaningful to you</em>, your opportunities become limitless.
        </p>

        <h2>So... what <em>is</em> Together Progress?</h2>

        <p>
          Together Progress is a website app that can help you <a>progress</a>.
          In it, you can set up "journeys" and "checkpoints" to plan your progress &mdash; and "check ins" to keep track of your progress.
          Think of it as a living-journal combined with a future-planner.
        </p>

        <p>
          For myself, I've added my wife, my kids, and myself as "journeys."
          When I come across a new activity that I'd like to do with them (or a new thing I'd like to teach them), I add it as a "checkpoint."
          When I make strides toward any of the "checkpoints", I add a quick "check in" about the accomplishment.
          It's absolutely incredible to look back and see how your relationships evolve and <em>progress</em> over time.
        </p>

        <p>
          When you start using TP, your data is stored <em>only on this device</em> &mdash; we don't have <em>any</em> access to it.
          We don't track a single byte about you!
          As a matter of fact, TP is a single-page app that you can download <a
            href="https://github.com/dallasread/app.togetherprogress.com"
            target="_blank"
          >here</a> and run completely on your own device or server.
        </p>

        <p>
          To level with you, this app is just the beginning &mdash; there's a lot more to come.
          <a href="mailto:hello@togetherprogress.com">Drop us a line</a> if you want to find out more!
        </p>

        <p v-if="!isNew">
          That's all for now &mdash; It's time to start making some progress!
          Start your first journey by clicking "Begin a new journey" in the sidebar.
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import TextCycle from '@/app/components/text-cycle/component.vue'

export default {
  components: {
    TextCycle
  },

  props: ['app'],

  computed: {
    isNew () {
      return this.app.queries.allJourneys().length === 0
    }
  },

  mounted () {
    this.app.setTitle('Together Progress')
  }
}
</script>
