<template>
  <div>
    <nav>
      <header>
        <a
          href="javascript:;"
          class="icon"
          @click="clickLogo"
        >
          <Icon :journey="currentJourney" />
        </a>
        <h1>{{ title }}</h1>
      </header>

      <div :class="`menu ${menuClass}`">
        <ul>
          <li>
            <ul>
              <li
                v-for="journey in journeys"
                :key="journey.id"
              >
                <router-link
                  :to="`/journeys/${journey.id}`"
                  :aria-label="`See ${journey.name}`"
                >
                  <div class="icon-wrapper">
                    <Icon
                      :journey="journey"
                      class="icon"
                    />
                  </div>
                  <span class="name">
                    {{ journey.name }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <router-link
              to="/journeys/new"
              aria-label="Start a journey"
            >
              <div class="icon-wrapper">
                <p class="icon plus">
                  +
                </p>
              </div>
              <h3 class="name">
                Begin a new journey
              </h3>
            </router-link>
          </li>
        </ul>

        <blockquote v-if="quote">
          <p>
            {{ quote.content }}
            <small>&mdash; {{ quote.author }}</small>
          </p>
        </blockquote>
      </div>
    </nav>

    <router-view
      v-if="!isLoading"
      :app="app"
    />
  </div>
</template>

<script>
import Commands from '@/commands/index.js'
import MultiEventStore from '@/state/multi-event-store.js'
import runners from '@/state/runners.js'
import Queries from '@/queries/index.js'
import StorageSettings from '@/storage-settings/storage-settings.js'
import Icon from '@/app/components/icon/component.vue'
import { saveAs } from 'file-saver'
import copyToClipboard from 'copy-to-clipboard'

export default {
  components: {
    Icon
  },

  props: {
    state: {
      type: Object,
      default: () => new MultiEventStore('together-progress', 'v4', runners)
    },
    saveAs: {
      type: Function,
      default: saveAs
    },
    copyToClipboard: {
      type: Function,
      default: copyToClipboard
    },
    blob: {
      type: Function,
      default: function () {
        return new Blob(...arguments)
      }
    },
    storageSettings: {
      type: Object,
      default: () => new StorageSettings('together-progress')
    }
  },

  data () {
    window.togetherProgress = this

    const queries = new Queries({
      state: this.state
    })
    const commands = new Commands({
      state: this.state,
      queries,
      saveAs: this.saveAs,
      copyToClipboard: this.copyToClipboard,
      blob: this.blob,
      storageSettings: this.storageSettings
    })

    return {
      app: this,
      queries,
      commands,
      isLoading: true,
      title: null,
      showMenu: false
    }
  },
  computed: {
    journeys () {
      return this.app.queries.allJourneys()
    },
    currentJourney () {
      return this.app.queries.findJourney(this.$route.params.id || this.$route.params.journeyId)
    },
    quote () {
      return this.app.queries.findQuote()
    },
    menuClass () {
      return this.showMenu ? ' show' : ''
    }
  },
  watch: {
    '$router.currentRoute.value.name' () {
      this.toggleMenu(false)
    }
  },
  mounted () {
    return this.commands.restoreFromLocal().then(() => {
      this.isLoading = false
    })
  },
  methods: {
    setTitle (title) {
      this.title = title
    },

    toggleMenu (val) {
      this.showMenu = val
    },

    clickLogo () {
      if (window.outerWidth <= 640) {
        this.toggleMenu(!this.app.showMenu)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
