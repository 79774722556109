<template>
  <JourneyHeader
    :app="app"
    :journey="journey"
  >
    <HintTip
      title="Use this hill chart to eliminate unknowns."
      content="Start getting items to the top of the hill. Once everything about the project is `known`, start moving the pieces down the hill!"
    />
    <HillChart
      :options="{
        width: chartWidth,
        height: 300,
        padding: 30,
        circleRadius: 15,
        lineWidth: 3,
        lineColor: journey.color,
        circleColors: [journey.color],
        labelColors: ['#25184e'],
        labelSize: '0.85em',
        uphillLabelText: 'Figuring things out',
        downhillLabelText: 'Getting stuff done',
        hillLabelColor: 'rgba(0, 0, 0, 0.2)',
      }"
      :data="journeyCheckpointsToHillchart"
      :on-update="updateCheckpointHillchart"
    />
  </JourneyHeader>
  <main>
    <div class="container">
      <TaskListForCheckpoint
        v-for="checkpoint in hillchartCheckpoints"
        :key="`task-list-${checkpoint.id}`"
        :app="app"
        :journey="journey"
        :checkpoint="checkpoint"
      />

      <AddCheckpoint
        :app="app"
        :journey="journey"
      />
    </div>
  </main>
</template>

<script>
import AddCheckpoint from '@/app/components/add-checkpoint/component.vue'
import HillChart from '@/app/components/hill-chart/component.vue'
import HintTip from '@/app/components/hint-tip/component.vue'
import JourneyHeader from '@/app/components/journey-header/component.vue'
import TaskListForCheckpoint from '../../components/task-list-for-checkpoint/component.vue'

export default {
  components: {
    AddCheckpoint,
    JourneyHeader,
    HillChart,
    HintTip,
    TaskListForCheckpoint
  },

  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    }
  },

  computed: {
    chartWidth () {
      return window.outerWidth <= 640 ? window.outerWidth : 1000
    },

    hillchartCheckpoints () {
      return this.app.queries.findAllCheckpointsForHillchart(this.journey)
    },

    journeyCheckpointsToHillchart () {
      return this.hillchartCheckpoints.map((checkpoint) => {
        return {
          id: checkpoint.id,
          title: checkpoint.name,
          color: this.journey.color,
          value: checkpoint.hillchart || 0
        }
      })
    }
  },

  methods: {
    updateCheckpointHillchart (chartCheckpoint, value) {
      this.app.commands.updateCheckpointHillchart(this.journey, chartCheckpoint.id, value)
    }
  }
}
</script>
