<template>
  <div
    class="checkpoint-task-list"
    :style="listStyle"
  >
    <h2>
      <input
        v-model="newCheckpointName"
        type="text"
        class="checkpoint-name"
        @keydown.up.prevent="goUp"
        @keydown.down.prevent="goDown"
        @blur="saveCheckpoint(checkpoint)"
      >

      <a
        href="javascript:;"
        style="opacity: 0.5; float: right;"
        class="checkpoint-remove"
        @click.prevent="removeCheckpoint(checkpoint)"
      >&times;</a>
    </h2>

    <ul
      v-if="showItems"
      class="checklist"
    >
      <TaskListItem
        v-for="task in app.queries.findAllTasksForCheckpoint(journey, checkpoint.id)"
        :key="`task-${task.id}`"
        :app="app"
        :journey="journey"
        :task="task"
        :checkpoint="checkpoint"
      />
      <li>
        <form
          class="wrapper"
          aria-label="Add new task for checkpoint"
          @submit.prevent="addTask"
        >
          <input
            type="checkbox"
            style="opacity: 0.5;"
            disabled
          >
          <input
            v-model="newTaskName"
            aria-label="New task"
            type="text"
            placeholder="Add a task..."
            @keydown.up.prevent="goUp"
            @keydown.down.prevent="goDown"
          >
        </form>
      </li>
    </ul>
  </div>
</template>

<script>
import TaskListItem from './task-list-item.vue'

export default {
  components: {
    TaskListItem
  },
  props: {
    app: {
      type: Object,
      default () { return {} }
    },
    journey: {
      type: Object,
      default () { return {} }
    },
    checkpoint: {
      type: Object,
      default () { return {} }
    },
    opacity: {
      type: Number,
      default () { return 1 }
    },
    showItems: {
      type: Boolean,
      default () { return true }
    }
  },
  data () {
    return {
      newTaskName: '',
      newCheckpointName: this.app.queries.findCheckpointNameForJourney(this.journey, this.checkpoint.id),
      newCheckInContent: '',
      newCheckInCompletion: false
    }
  },
  computed: {
    listStyle () {
      return `opacity: ${this.opacity}`
    }
  },
  methods: {
    addTask () {
      const task = { name: this.newTaskName }

      this.newTaskName = ''

      this.app.commands.addTaskForCheckpoint(this.journey, task, this.checkpoint)
    },
    goUp (event) {
      const allInputs = [...document.querySelectorAll('input[type="text"]')]
      const currentIndex = allInputs.indexOf(event.target)
      const previousInput = allInputs[currentIndex - 1]

      if (previousInput) {
        previousInput.focus()
        previousInput.setSelectionRange(0, 0)
      }
    },
    goDown () {
      const allInputs = [...document.querySelectorAll('input[type="text"]')]
      const currentIndex = allInputs.indexOf(event.target)
      const nextInput = allInputs[currentIndex + 1]

      if (nextInput) {
        nextInput.focus()
        nextInput.setSelectionRange(0, 0)
      }
    },
    addCheckIn () {
      let time = Date.parse(this.newCheckInTime)

      if (isNaN(time)) {
        time = Date.now()
      }

      const checkIn = {
        content: this.newCheckInContent,
        checkpointIds: [this.checkpoint.id],
        time
      }

      if (this.newCheckInContent.length) {
        this.app.commands.addCheckInToJourney(this.journey, checkIn)
      }

      if (this.newCheckInCompletion) {
        this.app.commands.completeCheckpointForJourney(this.journey, this.checkpoint)
      }

      this.newCheckInContent = ''
      this.newCheckInTime = null
      this.newCheckInCompletion = false
    },
    saveCheckpoint (checkpoint) {
      this.app.commands.updateCheckpointName(this.journey, checkpoint.id, this.newCheckpointName)
    },
    removeCheckpoint (checkpoint) {
      if (confirm('Are you sure you want to remove this checkpoint?')) {
        this.app.commands.removeCheckpointFromJourney(this.journey, checkpoint)
      }
    }
  }
}
</script>

<style lang="scss">
  .checklist {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 3rem;

    li {
      margin-bottom: 0.5rem;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    input[type="text"] {
      width: 90%;
    }
  }

  .checkpoint-name {
    width: 100%;
  }

  .checkpoint-remove {
    position: absolute;
    right: 0;
  }

  .checkpoint-task-list {
    position: relative;
    margin-bottom: var(--padding);
  }
</style>
